import { AiOutlineCalendar } from "react-icons/ai"
import { TfiRulerPencil } from "react-icons/tfi"
import { VscFileSubmodule } from "react-icons/vsc"
import { BiUser } from "react-icons/bi"

export const home = [
  {
    id:1,
    icon: <AiOutlineCalendar size={25} />,
    title: "+ 10 ans  d'experience sur le marché",
  },
  {
    id:2,
    icon: <TfiRulerPencil size={25} />,
    title: "Unique technologies  modernes d'impression",
  },
  {
    id:3,
    icon: <VscFileSubmodule size={25} />,
    title: "Plus de 20 entreprises satisfaites",
  },
  {
    id:4,
    icon: <BiUser size={25} />,
    title: "La satisfaction du client est notre priorité absolue.",
  },
]
export const expertise = [
  {
    id: 1,
    title: "Impression Offset",
    cover: "../images/offset.png",
    desc: [{ text: " Solutions traditionnelles pour des tirages moyens et grands, offrant des impressions de haute qualité avec des couleurs vives et précises. Idéal pour les brochures, les livres, les magazines, et les rapports annuels." }],
  },
  {
    id: 2,
    title: "Impression Grand Format",
    cover: "../images/grand-format.png",
    desc: [{ text: "Impression de grande taille pour des affiches, bâches, roll-ups, et plus encore. Parfait pour les expositions, les événements, et la publicité extérieure. Nous utilisons des encres durables qui résistent aux intempéries." }],
  },
  {
    id: 3,
    title: "Impression Numérique",
    cover: "../images/impression-grand-format.jpg",
    desc: [{ text: "Solutions rapides et efficaces pour des petites séries. Idéal pour des projets nécessitant une livraison rapide sans compromettre la qualité. Utilisation de technologies de pointe pour garantir des impressions nettes et précises." }],
  },
  {
    id: 4,
    title: "Impression de Cartes de Visite",
    cover: "../images/cartes.png",
    desc: [{ text: "Créez des cartes de visite professionnelles et personnalisées qui laissent une impression durable. Choisissez parmi une variété de finitions, de papiers, et de designs." }],
  },
  {
    id: 5,
    title: "Impression d'Étiquettes",
    cover: "../images/etiquettes.png",
    desc: [{ text: "Conception et impression d'étiquettes adhésives de haute qualité pour tous vos besoins commerciaux. Personnalisez vos étiquettes avec votre logo et vos informations." }],
  },
  {
    id: 6,
    title: "Création de Cachets",
    cover: "../images/cash1.png",
    desc: [{ text: "Fabrication sur mesure de cachets pour les entreprises, les institutions et les particuliers. Personnalisez vos cachets avec des logos, des signatures, et plus." }],
  },
  {
    id: 7,
    title: "Travaux de Sérigraphie",
    cover: "../images/serigraphie.jpg",
    desc: [{ text: "Sérigraphie sur divers supports, y compris les textiles et les objets promotionnels. Offrez des articles uniques et de qualité à vos clients ou employés" }],
  },
  {
    id: 8,
    title: "Broderie sur Uniformes et Chapeaux",
    cover: "../images/broderie.jpg",
    desc: [{ text: "Broderie de haute qualité sur uniformes, chapeaux, et autres articles vestimentaires. Parfait pour renforcer l'identité visuelle de votre entreprise." }],
  },
  {
    id: 9,
    title: "Marquage et Habillage de Véhicules",
    cover: "../images/marquage.jpg",
    desc: [{ text: "Personnalisation de véhicules avec votre marque. Idéal pour la publicité mobile et renforcer la visibilité de votre entreprise." }],
  },
  {
    id: 10,
    title: "Personnalisation de Gadgets Promotionnels",
    cover: "../images/Gagtes.png",
    desc: [{ text: "Impression et marquage de gadgets promotionnels tels que stylos, mugs, clés USB, et autres objets publicitaires. Renforcez votre image de marque avec des articles promotionnels de qualité." }],
  },
  {
    id: 11,
    title: "Vente de Fournitures de Bureau",
    cover: "../images/vente.png",
    desc: [{ text: "Une large gamme de fournitures de bureau de haute qualité pour répondre à tous vos besoins professionnels. Des stylos aux meubles de bureau, nous avons tout ce qu'il vous faut." }],
  },
  {
    id: 12,
    title: "Papeterie",
    cover: "../images/papeterie.png",
    desc: [{ text: "Fourniture de tous types de produits de papeterie, y compris des cahiers, des blocs-notes, des enveloppes, et du papier à lettres. Parfait pour les entreprises et les particuliers." }],
  },
]
export const testimonial = [
  {
    id: 1,
    name: "Alexander Black",
    cover: "../images/e1.jpg",
    post: "Seven consulting - CEO",
    desc: "Nunc fermentum - tempus erat ligula, sit amet lacinia justo cursus ac. Suspendisse quis nulla tincidunt! Lorem ipsum dolor amet at ornare ex, quis fringilla tortor! Nunc consectetur feugiat rutrum. Sed rhoncus sapien!",
  },
  {
    id: 2,
    name: "Diana Green",
    cover: "../images/e2.jpg",
    post: "Seven Arts - marketing manager",
    desc: "Cras at ornare fermentum quam et tortor euismod, vel maximus metus tristique at ornare ex, quis fringilla tortor. Aenean semper neque quis consectetur lobortis. Quisque nec convallis ex. Aenean ut metus et nunc cursus aliquet.",
  },
  {
    id: 3,
    name: "Alexander Black",
    cover: "../images/e3.jpg",
    post: "Seven consulting - CEO",
    desc: "Nunc fermentum - tempus erat ligula, sit amet lacinia justo cursus ac. Suspendisse quis nulla tincidunt! Lorem ipsum dolor amet at ornare ex, quis fringilla tortor! Nunc consectetur feugiat rutrum. Sed rhoncus sapien!",
  },
  {
    id: 4,
    name: "Diana Green",
    cover: "../images/e4.jpg",
    post: "Seven Arts - marketing manager",
    desc: "Cras at ornare fermentum quam et tortor euismod, vel maximus metus tristique at ornare ex, quis fringilla tortor. Aenean semper neque quis consectetur lobortis. Quisque nec convallis ex. Aenean ut metus et nunc cursus aliquet.",
  },
]
export const showcase = [
  {
    id: 1,
    title: "Neon Lights",
    cover: "../images/cartes.png",
    catgeory: "DIGITAL MARKETING",
  },
  {
    id: 2,
    title: "IMPRIMERIE",
    catgeory: "WEBSITES",
    cover: "../images/cartes.png",
  },
  {
    id: 3,
    title: "BUREAUTIQUE",
    cover: "../images/cartes.png",
    catgeory: "WEBSITES",
  },
  {
    id: 4,
    title: "INFORMATIQUE",
    cover: "../images/cartes.png",
    catgeory: "BRANDING	",
  },
  {
    id: 5,
    title: "SERVICE CLIENTELE",
    cover: "../images/cartes.png",
    catgeory: "	",
  },
  
]
export const brand = [
  {
    id: 1,
    cover: "../images/Logo.png",
  },
  {
    id: 2,
    cover: "../images/Logo1.png",
  },
  {
    id: 3,
    cover: "../images/brand3.jpg",
  },
  {
    id: 4,
    cover: "../images/brand4.png",
  },
]
export const blogdata = [
  {
    id: 1,
    title: "L’ENGAGEMENT DE LA DIRECTION SUR LA PROTECTION DE L’ENVIRONNEMENT ",
    cover: "../images/blog.png",
    catgeory: "INDUSTRY",
    date: "JANUARY 12, 2023",
  },
]
export const teamdata = [
  {
    id: 1,
    title: "Mike MUHIMUZI",
    cover: "../images/mike.jpg",
    post: "DIRECTEUR de la societe BISMOS",
  },
]
