import { useEffect } from "react";
import Banner from "../components/Banner";
import Brand from "../components/Brand";
import Testimonial from "../components/Testimonial";
import { Title, TitleSm } from "../components/common/Title";
import { FaWhatsapp } from "react-icons/fa";
import { Helmet } from "react-helmet";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";

const Agency = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Helmet>
        <title>About us</title>
      </Helmet>
      <Header/>
      <section className="agency bg-top">
        <div className="container">
          <div className="heading-title">
            <TitleSm title="À Propos de Nous " /> <br />
            <br />
            <Title title="Notre mission, notre vision, et nos valeurs" className="title-bg" />
          </div>

          <div className="content flex1">
            <div className="left w-60 py">
              <TitleSm title="Notre Mission" />
              <p className="desc-p">
                Chez BISMOS, notre mission est de fournir des services et des produits de haute qualité qui répondent aux besoins variés de nos clients, tout en adoptant des pratiques durables et respectueuses de l'environnement.
              </p>
              <div className="grid-3">
                <div className="box">
                  <h1 className="indigo">10+</h1>
                  <h3>Des années d'expérience</h3>
                </div>
              </div>
            </div>
            <div className="right w-40 ml">
              <img src="/images/s1.jpg" alt="Img" className="round" width="100%" height="100%" />
            </div>
          </div>

          <div className="content flex">
            <div className="left w-40 py">
              <img src="/images/s4.jpg" alt="Img" className="round" width="100%" height="100%" />
            </div>
            <div className="right w-60 ml">
              <TitleSm title="Notre Vision" />
              <br />
              <p className="misson-p">
                Devenir le leader de l'industrie de l'impression et des fournitures de bureau en Afrique, reconnu pour notre innovation, notre qualité, et notre engagement envers la satisfaction du client.
              </p>
            </div>
          </div>

          <div className="content flex">
            <div className="left w-40 py">
              <TitleSm title="Nos Valeurs" />
              <br />
              <p className="misson-p">
                • Qualité: Nous nous engageons à fournir des produits et services de la plus haute qualité.<br />
                • Innovation: Nous cherchons constamment à innover pour offrir des solutions meilleures et plus efficaces.<br />
                • Durabilité: Nous adoptons des pratiques écologiques pour protéger notre planète.<br />
                • Service Client: La satisfaction de nos clients est notre priorité absolue.
              </p>
            </div>
            <div className="right w-60 ml">
              <img src="/images/s4.jpg" alt="Img" className="round" width="100%" height="100%" />
            </div>
          </div>
        </div>
      </section>

      <Brand />
      <Testimonial />
      <Banner />
      <br />
      <br />
      <br />
      <br />

      <a
        href="https://wa.me/25779967624" // Replace with your WhatsApp number
        style={{
          position: "fixed",
          width: "60px",
          height: "60px",
          bottom: "40px",
          right: "40px",
          backgroundColor: "#25D366",
          color: "#FFF",
          borderRadius: "50px",
          textAlign: "center",
          fontSize: "30px",
          boxShadow: "2px 2px 3px #999",
          zIndex: "1000",
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp style={{ marginTop: "15px" }} />
      </a>
      <Footer/>
    </>
  );
};

export default Agency;
