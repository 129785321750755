import { home } from "../assets/data/dummydata";
import Banner from "../components/Banner";
import Expertise from "../components/Expertise";
import ShowCase from "../components/ShowCase";
import Testimonial from "../components/Testimonial";
import { Title, TitleSm } from "../components/common/Title";
import React,{ useEffect } from "react";
import WhatsAppButton from "./WhatsAppButton";
import Footer from "../components/common/Footer";
import { Helmet } from "react-helmet";
import Header from "../components/common/Header";
import BlogCard from "../components/BlogCard";
import Brand from "../components/Brand";

const Hero = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <Helmet>
        <title>BISMOS PRINT || HOME</title>
      </Helmet>
      <Header/>
      <section className="hero">
        <div className="container">
          <div className="img">
            <img
              src="/images/ILLUSTRATION LOGO BISMOS .png" // Update the path
              alt="Logo"
              height={150} // Adjust the height and width as needed
              width={150}
            />
          </div>
          {/* <TitleLogo title="creative" caption="7" className="logobg" /> */}
          <h1 className="hero-title">Bismos Print</h1>

          <div className="sub-heading">
            <TitleSm title="Imprimerie" /> <span>.</span>
            <TitleSm title="Bureautique" /> <span>.</span>
            <TitleSm title="Informatique" />
          </div>
        </div>
      </section>
      <section className="hero-sec">
        <div className="container">
          <div className="heading-title">
            <Title title="Bienvenue chez BISMOS où La qualité fait la différence" />
            <p>
              Votre partenaire de confiance pour les impressions de qualité, les
              fournitures de bureau, et les équipements informatiques.
              L'Entreprise BISMOS est une Société dont la spécialité réside dans
              les travaux d'impressions de vente de matériels de bureau et des
              équipements informatiques.
            </p>
          </div>
          <div className="hero-content grid-4">
            {home.map((item, i) => (
              <div className="box" key={i}>
                <span className="green">{item.icon}</span> <br />
                <br />
                <h3>{item.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Expertise />
      <Banner />
      <Testimonial />
      <ShowCase />
      <Brand />

      <div className="text-center">
        <Title title="
Dernières nouvelles et articles" />
      </div>
      <BlogCard />
      <Footer/>
      <WhatsAppButton/>
    </>
  );
};

export default Hero;
