import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FaWhatsapp } from 'react-icons/fa';
import { blogdata } from '../../assets/data/dummydata'; // Adjust the path as needed
import Banner from '../../components/Banner'; // Adjust the path as needed
import { Title, TitleSm } from '../../components/common/Title'; // Adjust the path as needed
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

const SinglePost = () => {
  const { id } = useParams();
  const post = blogdata.find((post) => post.id === parseInt(id));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Handle case where post is undefined (e.g., invalid id)
  if (!post) {
    return (
      <div>
        <p>Loading...</p> {/* Placeholder for loading state */}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
      </Helmet>
      <Header/>
      <section className="post-details bg-top">
        <div className="container">
          <div className="heading-title">
            <TitleSm title="TIPS & TRICKS / JANUARY 12, 2022" /> <br />
            <br />
            <Title title={post.title} className="title-bg" />
            <div className="img py">
              <img
                src={post.cover}
                alt={post.title}
                width="100%"
                height="100%"
                className="round"
              />
            </div>
            <div className="desc">
              <TitleSm title="L’ENGAGEMENT DE LA DIRECTION SUR LA PROTECTION DE L’ENVIRONNEMENT " />
              <p className="desc-p">
                Je m’engage, en tant que citoyen de cette planète et directeur
                de notre imprimerie, à œuvrer à l’amélioration continue de notre
                organisation et de ses performances en termes de qualité et
                d’environnement, afin de pérenniser l’entreprise face à
                l’évolution des marchés.
              </p>
              <p className="desc-p">
                Pour cela, je m'engage à prévenir la pollution et à appliquer la
                charte d’excellence qualité du réseau Imprimerie au Burundi.
                Nous allons établir et assurer l’amélioration continue de notre
                Système de Management Environnement et Qualité (SMEQ).
              </p>
              <p className="desc-p">
                Nous adopterons une approche par les risques pour chacun de nos
                processus et nous nous assurerons d’être en conformité avec la
                réglementation environnementale et les autres exigences en
                vigueur ou à venir. De plus, nous nous engageons à améliorer la
                conscience environnementale et qualité de nos collaborateurs, en
                encourageant les initiatives individuelles et collectives.
              </p>
            </div>
          </div>
          <Banner />
          <div className="heading-title">
            {/* <div className="desc">
              <TitleSm title="Integer a justo vitae arcu fermentum..." />
              <p className="desc-p"> </p>
              <p className="desc-p"></p>
              <p className="desc-p"></p>
              <p className="desc-p"></p>
            </div> */}
          </div>
        </div>
      </section>
      <a
        href="https://wa.me/25779967624" // Replace with your WhatsApp number
        style={{
          position: 'fixed',
          width: '60px',
          height: '60px',
          bottom: '40px',
          right: '40px',
          backgroundColor: '#25D366',
          color: '#FFF',
          borderRadius: '50px',
          textAlign: 'center',
          fontSize: '30px',
          boxShadow: '2px 2px 3px #999',
          zIndex: '1000',
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp style={{ marginTop: '15px' }} />
      </a>
      <Footer/>
    </>
  );
};

export default SinglePost;
