// WhatsAppButton.js
import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/25779967624" // Replace with your WhatsApp number
      style={{
        position: "fixed",
        width: "60px",
        height: "60px",
        bottom: "40px",
        right: "40px",
        backgroundColor: "#25D366",
        color: "#FFF",
        borderRadius: "50px",
        textAlign: "center",
        fontSize: "30px",
        boxShadow: "2px 2px 3px #999",
        zIndex: "1000",
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp style={{ marginTop: "15px" }} />
    </a>
  );
};

export default WhatsAppButton;
