import { Helmet } from "react-helmet"
import { expertise } from "../assets/data/dummydata"
import { Card } from "../components/common/Card"
import { Title, TitleSm } from "../components/common/Title"
import React, { useEffect } from "react"
import Header from "../components/common/Header"
import WhatsAppButton from "./WhatsAppButton"
import Footer from "../components/common/Footer"

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Helmet>
        <title>Service</title>
      </Helmet>
      <Header/>
      <section className='agency bg-top'>
        <div className='container'>
          <div className='heading-title'>
            <TitleSm title='Nos Services' /> <br />
            <br />
            <Title title='Des solutions complètes pour tous vos besoins en impression, fournitures de bureau, et équipements informatiques' className='title-bg' />
          </div>
          <div className='grid-2 py'>
            {expertise.map((item) => (
              <Card data={item} key={item.id} caption={item.post} show={true} />
            ))}
          </div>
        </div>
      </section>
      <WhatsAppButton/>
      <Footer/>
    </>
  )
}

export default Services
