
import { Helmet } from "react-helmet"
import BlogCard from "../components/BlogCard"
import { Title, TitleSm } from "../components/common/Title"
import React, { useEffect } from "react"
import { FaWhatsapp } from "react-icons/fa"
import Header from "../components/common/Header"
import Footer from "../components/common/Footer"

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Helmet>
        <title>Blog</title>
      </Helmet>
      <Header/>
      <section className='agency bg-top'>
        <div className='container'>
          <div className='heading-title'>
            <TitleSm title='BLOG' /> <br />
            <br />
            <Title title='Nos points de vue sur le marketing, le design et la technologie' />
          </div>
          <BlogCard />
        </div>
      </section>
      <a
        href="https://wa.me/25779967624" // Replace with your WhatsApp number
        style={{
          position: "fixed",
          width: "60px",
          height: "60px",
          bottom: "40px",
          right: "40px",
          backgroundColor: "#25D366",
          color: "#FFF",
          borderRadius: "50px",
          textAlign: "center",
          fontSize: "30px",
          boxShadow: "2px 2px 3px #999",
          zIndex: "1000",
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp style={{ marginTop: "15px" }} />
      </a>
      <Footer/>
    </>
  )
}

export default Blog
