import { Helmet } from "react-helmet"
import { teamdata } from "../assets/data/dummydata"
import { Card } from "../components/common/Card"
import { Title, TitleSm } from "../components/common/Title"
import React, { useEffect } from "react"
import { FaWhatsapp } from "react-icons/fa"
import Header from "../components/common/Header"
import Footer from "../components/common/Footer"

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Helmet>
        <title>Team</title>
      </Helmet>
      <Header/>
      <section className='agency bg-top'>
        <div className='container'>
          <div className='heading-title'>
            <TitleSm title='MEET OUR TEAM' /> <br />
            <br />
            <Title title='A team of smart & passionate creatives' className='title-bg' />
          </div>
          <div className='grid-4 py'>
            {teamdata.map((item) => (
              <Card data={item} key={item.id} caption={item.post} />
            ))}
          </div>
        </div>
      </section>
      <a
        href="https://wa.me/25779967624" // Replace with your WhatsApp number
        style={{
          position: "fixed",
          width: "60px",
          height: "60px",
          bottom: "40px",
          right: "40px",
          backgroundColor: "#25D366",
          color: "#FFF",
          borderRadius: "50px",
          textAlign: "center",
          fontSize: "30px",
          boxShadow: "2px 2px 3px #999",
          zIndex: "1000",
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp style={{ marginTop: "15px" }} />
      </a>
      <Footer/>
    </>
  )
}

export default Team
