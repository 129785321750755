import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RiMenu4Line } from 'react-icons/ri';
import { AiOutlineClose } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';

const Header = () => {
  const [activeLink, setActiveLink] = useState('');
  const [open, setOpen] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleClick = () => {
    window.open('https://wa.me/25779967624', '_blank');
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img
                src="/images/ILLUSTRATION LOGO BISMOS .png" // Update with your logo path
                alt="Logo"
                height={50} // Adjust as needed
                width={50} // Adjust as needed
              />
            </Link>
          </div>
          <nav className={open ? 'openMenu' : 'closeMenu'} onClick={() => setOpen(false)}>
            <Link to="/" className={activeLink === '/' ? 'activeLink' : 'none'}>
              Accueil
            </Link>
            <Link to="/about_us" className={activeLink === '/about_us' ? 'activeLink' : 'none'}>
              Qui Sommes Nous
            </Link>
            <Link to="/services" className={activeLink === '/services' ? 'activeLink' : 'none'}>
              Services
            </Link>
            <Link to="/team" className={activeLink === '/team' ? 'activeLink' : 'none'}>
              Notre Équipe
            </Link>
            <Link to="/showcase" className={activeLink === '/showcase' ? 'activeLink' : 'none'}>
              Projects
            </Link>
            <Link to="/blogs" className={activeLink === '/blogs' ? 'activeLink' : 'none'}>
              Blog
            </Link>
            {/* Uncomment below if you have a contact page */}
            {/* <Link to="/contact" className={activeLink === '/contact' ? 'activeLink' : 'none'}>
              Contact
            </Link> */}
            <button className="button-primary" onClick={handleClick}>
              <FaWhatsapp style={{ marginRight: '8px' }} />
              +257 79 96 76 24
            </button>
          </nav>
          <button onClick={() => setOpen(!open)}>
            {open ? <AiOutlineClose size={25} /> : <RiMenu4Line size={25} />}
          </button>
        </div>
      </header>
    </>
  );
};

export default Header;
