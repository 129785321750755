import React from "react"
import { TitleLogo } from "./Title"
import { BsFacebook } from "react-icons/bs"
import { AiFillBehanceCircle, AiFillInstagram, AiFillLinkedin } from "react-icons/ai"
import { Link } from "react-router-dom"

const Footer = () => {

  const handleClick = () => {
    window.open("https://www.hyphentech.tech", "_blank")
  };
  return (
    
    <>
      <footer>
        <div className='container'>
          <div className='grid-4'>
            <div className='logo'>
              <TitleLogo title='Bismos' caption='' className='logobg' />
            </div>
            <ul>
              <h3>COMPANY</h3>
              <li>
                <Link to='/'>Qui Sommes Nous</Link>
              </li>
              <li>
                <Link to='/'>Notre Équipe</Link>
              </li>
              <li>
                <Link to='/'>Projects</Link>
              </li>
              <li>
                <Link to='/'>Blog</Link>
              </li>
            </ul>
            <ul>
              <h3>SERVICES</h3>
              <li>
                <Link to='/services'>Impression Offset</Link>
              </li>
              <li>
                <Link to='/services'>Branding & Creative Services</Link>
              </li>
              <li>
                <Link to='/services'>Création de Cachets</Link>
              </li>
              <li>
                <Link to='/services'>Marquage et Habillage de Véhicules</Link>
              </li>
            </ul>
            <ul>
              <h3>CONNECT</h3>
              <div className='connect'>
                <li>
                  <Link to='/'>
                    <BsFacebook size={25} />
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <AiFillBehanceCircle size={25} />
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <AiFillInstagram size={25} />
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <AiFillLinkedin size={25} />
                  </Link>
                </li>
              </div>
            </ul>
          </div>
          <div className='legal connect py'>
            <div className='text'>
              <span>Designed by: </span>
              < button  onClick={handleClick}>HYPHEN TECH</button>
            </div>
            <div className='connect'>
              <span>Bismos Imprimerie</span>
              <span> &nbsp; | &nbsp; </span>
              <span>All rights reserved</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
