import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import SinglePost from './sections/blogs/[id]';
import Hero from './sections/Hero';
import Agency from './sections/Agency';
import Blog from './sections/Blog';
import Services from './sections/Services';
import ShowCase from './sections/ShowCase';
import Team from './sections/Team';


function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" exact element={<Hero/>}/>
      <Route path="/about_us" element={<Agency/>} />
        <Route path="/blogs" element={<Blog/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/showcase" element={<ShowCase/>} />
        <Route path="/team" element={<Team/>} />
        <Route path="/blogs/:id" element={<SinglePost/>} />

      </Routes>
    </Router>
  )
}

export default App
